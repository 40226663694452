.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.app-header {
  /* background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; */
}

.container {
  background-color: #fff;
  border-radius: 3px !important;
}

.btn-link {
  color: #707070 !important;
  text-decoration: none !important;
  font-weight: 600;
}

.app-link-themed {
  color:#383C42 !important;
  font-family: "Lato", sans-serif;
  font-size: 15px !important;
  text-decoration: none !important;

}

.app-link-themed:hover {
  color: #087189 !important;
  text-decoration: underline !important;

}

.primary {
  color: #fff !important;
  background-color: #007FA3 !important;
}

.secondary {
  color: #fff !important;
  background-color: #007FA3 !important;
}

.btn {
  border-radius: 3px !important;
}

.card {
  border: none !important;
}

.card-header {
  background-color: #fff !important;
}

.form-control {
  border-radius: 3px !important;
}

.form-control.search {
  border: none;
  border-bottom: 1px solid #707070;
}

/* .form-check-input:checked {
  color: white;
  border-color: #441968 !important;
  background: #441968 !important;
} */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.bg-hover-grey-superlight:hover {
  background: #F5F5F5 !important;
}

.color-grey {
  color: #707070 !important;
}

iconify-icon{
  height: 13px;
}

.date-picker{
  border: 1px solid #ced4da;
  /* padding: 2px 2px 2px 10px; */
  padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
  color: #212529;
  font-size: .875rem;
}

.modalCloseButton{
  background-color: #fff;
  cursor: pointer;
  width: 20px;
  padding-left: 6px;
  font-weight: 700;
}
.modalCloseButton:hover{
  background-color: #c4c4c4;
  border-radius: 5px;
}
.custom-width-multiselect {
  width: 250px; /* Set your desired width */
  text-align: left;
  }

  #sessionDetails.modal-dialog {
    max-width: 90%;
  }

  .list-group-item
{

  color:#383C42 !important;
}
.table {
  color:#383C42 !important;
}
.toastpopup{
  max-height: 300px;
  /* min-height: 300px; */
  overflow-y: auto;
  padding: 5px;
  scrollbar-width: thin;
}
.tostbodyclass{
  background-color: white;
}